import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/images/zredi logo-02.png';
import { MdEmail, MdKeyboardArrowRight } from 'react-icons/md';
function Footer() {
  return (
    <footer className="flex flex-col items-center px-5 pt-20 pb-8 mt-16 w-full font-sora max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-10 justify-evenly w-full max-w-[1219px] max-md:flex-wrap max-md:flex-col max-md:gap-5">
        <div className="flex flex-col text-base">
          <img src={logo} alt="Logo" className='w-44 h-auto max-md:w-40' />
          <p className="mt-8 max-md:mt-5">
          HIG-95, <br /> Ground Floor, <br />Kanan Vihar Phase-1, <br /> Patia-751024, Bhubaneswar, <br /> Odisha.
          </p>
        </div>
        <nav className="flex flex-col self-start mt-2.5 max-md:mt-5">
          <h3 className="text-lg font-bold">Quick Links</h3>
          <ul className="mt-8 text-base leading-8 max-md:mt-4 max-md:leading-7">
            <li className='hover:text-[#076EAB]'><Link className='flex items-center gap-2' to="/"><MdKeyboardArrowRight />Home</Link></li>
            <li className='hover:text-[#076EAB]'><Link className='flex items-center gap-2' to="/about"><MdKeyboardArrowRight />About</Link></li>
            <li className='hover:text-[#076EAB]'><Link className='flex items-center gap-2' to="/services"><MdKeyboardArrowRight />Services</Link></li>
            <li className='hover:text-[#076EAB]'><Link className='flex items-center gap-2' to="/contact"><MdKeyboardArrowRight />Contact Us</Link></li>
          </ul>
        </nav>
        <div className="flex flex-col self-start mt-2.5 text-base max-md:mt-5">
          <h3 className="text-lg font-bold">Contact Us</h3>
          <p className="mt-8 max-md:mt-4">
            For inquiries or support, reach out to us <br /> and we’ll be happy to assist you.
          </p>
          <div className="flex items-center gap-2 mt-6 max-md:mt-4"><MdEmail/>info@zredi.com</div>
        </div>
      </div>
      <div className="self-stretch mt-16 w-full border border-solid border-neutral-300 min-h-[1px] max-md:mt-10 max-md:max-w-full" />
      <div className="mt-9 text-center text-sm max-md:mt-5">
        &copy; 2025 Zredi Technology Pvt Ltd. <br />All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;