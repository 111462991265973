import React, { useState } from 'react';
import {motion} from 'framer-motion'
import emailjs from '@emailjs/browser';

const ContactForm = ({theme}) => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fullName = `${formData.firstname} ${formData.lastname}`;
    const emailParams = {
      from_name: fullName,
      email_id: formData.email,
      message: formData.message
    };
    
    emailjs.send('service_a21m7vc', 'template_2qxfs5v', emailParams,{publicKey: 'rUE5p6Vkx2hyG9d42'})
      .then((response) => {
        alert('Message sent successfully!');
        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          message: ''
        });
      }, (error) => {
        console.log(error);
        alert('Failed to send the message, please try again.');
      });
  };

  return (
    <section className="flex flex-col items-center self-stretch pb-10 mt-10 w-full text-sm font-code max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <form onSubmit={handleSubmit} className="mt-7 w-full max-w-[671px]">
        <div className="flex w-full gap-5">
      <div className="mb-7 w-full">
          <label htmlFor="firstname" className="block mb-3 tracking-tight">First Name</label>
          <motion.input 
            initial={{x:-100, opacity:0}} animate={{x:0, opacity:1}} transition={{duration:1}}
            type="text" 
            id="firstname"
            value={formData.firstname}
            onChange={handleChange} 
            className={`w-full pl-3 h-14 rounded-lg border border-solid ${theme === 'dark' ? 'bg-white text-black' : 'bg-black bg-opacity-60 text-white'}`} 
            required 
          />
        </div>
        <div className="mb-7 w-full">
          <label htmlFor="lastname" className="block mb-3 tracking-tight">Last Name</label>
          <motion.input
            initial={{x:100, opacity:0}} animate={{x:0, opacity:1}} transition={{duration:1}} 
            type="text" 
            id="lastname"
            value={formData.lastname}
            onChange={handleChange} 
            className={`w-full pl-3 h-14 rounded-lg border border-solid ${theme === 'dark' ? 'bg-white text-black' : 'bg-black bg-opacity-60 text-white'}`}
            required 
          />
        </div>
        </div>
        <div className="mb-7">
          <label htmlFor="email" className="block mb-3 tracking-tight">Email Address</label>
          <motion.input
            initial={{y:100, opacity:0}} animate={{y:0, opacity:1}} transition={{duration:1}} 
            type="email" 
            id="email"
            value={formData.email}
            onChange={handleChange} 
            className={`w-full pl-3 h-14 rounded-lg border border-solid ${theme === 'dark' ? 'bg-white text-black' : 'bg-black bg-opacity-60 text-white'}`}
            required 
          />
        </div>
        <div className="mb-10">
          <label htmlFor="message" className="block mb-3 tracking-tight">Message</label>
          <motion.textarea 
            initial={{y:100, opacity:0}} animate={{y:0, opacity:1}} transition={{duration:1}} 
            id="message" 
            value={formData.message}
            onChange={handleChange}
            className={`w-full pl-3 pt-3 h-[230px] rounded-lg border border-solid ${theme === 'dark' ? 'bg-white text-black' : 'bg-black bg-opacity-60 text-white'}`} 
            required
          ></motion.textarea>
        </div>
        <motion.button whileHover={{scale:1.1}} type="submit" className="px-5 py-4 text-white text-base bg-[#076EAB] rounded-full max-md:px-5">
          Send Message
        </motion.button>
      </form>
    </section>
  );
};

export default ContactForm;